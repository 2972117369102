$(function(){

	displayAjust();

	window.onresize=function(){
		displayAjust();
	}

	// $("#freeapi-menu").mouseenter(function() {
	// 	$(".freeapi-menu").removeClass("hide");
	// });

	// $(".freeapi-menu").mouseleave(function() {
	// 	$(".freeapi-menu").addClass("hide");
	// });


});

function displayAjust() {
	// weizhang & xianxing block
	// $("#homepageContainer").css("height", getBrowserHeight()-150+"px");
	// about
	$(".about-main-container").css("margin-left", (getBrowserWidth() - "800")/2 + "px");
	// xianxing modal
	$(".modal-content").css("top", (getBrowserHeight()-600)/2);
}

function getBrowserWidth() {
	if (window.innerWidth)
		winWidth = window.innerWidth;
	else if ((document.body) && (document.body.clientWidth))
		winWidth = document.body.clientWidth;
	return winWidth;
}

function getBrowserHeight() {
	if (window.innerHeight)
		winHeight = window.innerHeight;
	else if ((document.body) && (document.body.clientHeight))
		winHeight = document.body.clientHeight;
	return winHeight;
}
