$(document).ready(function () {

    $("#localCarBtn").on("click", function () {
        $("#localCarBtn").removeClass("switchDefault").addClass("switchOn")
        $("#foreignCarBtn").removeClass("switchOn").addClass("switchDefault")
        $("#foreignCar").hide()
        $("#localCar").show()
    })

    $("#foreignCarBtn").on("click", function () {
        $("#foreignCarBtn").removeClass("switchDefault").addClass("switchOn")
        $("#localCarBtn").removeClass("switchOn").addClass("switchDefault")
        $("#localCar").hide()
        $("#foreignCar").show()
    })

    $(".table-bordered tr:nth-child(2) td:first-child").prepend("今天(").append(" )")
    $(".table-bordered tr:nth-child(2) td:nth-child(2)").prepend("明天(").append(" )")
})

function showMap(geoInfos) {
    let centerPoint = getCenterPoint(geoInfos)

    let map = new AMap.Map('areaMap', {
        resizeEnable: true,
        center: centerPoint,
        zoom: 13
    })

    drawMapWithGeoInfos(map, geoInfos)

    $('#resetBtn').on("click", function (e) {
        map.setFitView()
    })

    let $mapModal = $('#mapModal')

    $mapModal.on('shown.bs.modal', function (e) {
        map.setFitView()
    })

    $mapModal.modal("show")
}

function getCenterPoint(geoInfos) {
    let x = 0
    let y = 0
    let count = 0

    for (const geoInfo of geoInfos) {
        for (const geo of geoInfo.geo) {
            x += parseFloat(geo.x)
            y += parseFloat(geo.y)
            count += 1
        }
    }

    return [x / count, y / count]
}

function drawMapWithGeoInfos(map, geoInfos, extdata) {
    for (const geoInfo of geoInfos) {
        let path = geoInfo.geo.map(function (geo) {
            return [geo.x, geo.y]
        })

        for (const info of geoInfo.info) {
            if (info.isrestrict) {
                switch (info.shapetype) {
                    case 0 :
                        drawPolylineLimit(map, path, extdata)
                        break
                    case 1 :
                        drawPolygon(map, path, extdata)
                        break
                    default :
                        drawPolylineAndPolygonLimit(map, path, extdata)
                }
            } else {
                switch (info.shapetype) {
                    case 0 :
                        drawPolyline(map, path, extdata)
                        break
                    case 1 :
                        drawPolygon(map, path, extdata)
                        break
                    default :
                        drawPolylineAndPolygon(map, path, extdata)
                }
            }
        }
    }
}

function drawPolyline(map, path, extdata) {
    let polyline = new AMap.Polyline({
        path: path,          //设置线覆盖物路径
        strokeColor: "#00FF00", //线颜色
        strokeOpacity: 2,       //线透明度
        strokeWeight: 5,        //线宽
        strokeStyle: "solid",   //线样式
        strokeDasharray: [10, 5] //补充线样式
    })
    polyline.setMap(map)

    if (extdata !== undefined) {
        polyline.setExtData(extdata)
    }
}

function drawPolylineLimit(map, path, extdata) {
    let polyline = new AMap.Polyline({
        path: path,          //设置线覆盖物路径
        strokeColor: "#FF0000", //线颜色
        strokeOpacity: 1,       //线透明度
        strokeWeight: 5,        //线宽
        strokeStyle: "solid",   //线样式
        strokeDasharray: [10, 5] //补充线样式
    })
    polyline.setMap(map)

    if (extdata !== undefined) {
        polyline.setExtData(extdata)
    }
}

function drawPolygon(map, path, extdata) {
    let polygon = new AMap.Polygon({
        path: path,//设置多边形边界路径
        strokeColor: "", //线颜色
        strokeOpacity: 1, //线透明度
        strokeWeight: 3,    //线宽
        fillColor: "#FF0000", //填充色
        fillOpacity: 0.2//填充透明度
    })
    polygon.setMap(map)

    if (extdata !== undefined) {
        polygon.setExtData(extdata)
    }
}

function drawPolylineAndPolygonLimit(map, path, extdata) {
    let polygon = new AMap.Polygon({
        path: path,//设置多边形边界路径
        strokeColor: "#FF0000", //线颜色
        strokeOpacity: 1, //线透明度
        strokeWeight: 3,    //线宽
        fillColor: "#FF0000", //填充色
        fillOpacity: 0.2//填充透明度
    })
    polygon.setMap(map)

    if (extdata !== undefined) {
        polygon.setExtData(extdata)
    }
}

function drawPolylineAndPolygon(map, path, extdata) {
    let polygon = new AMap.Polygon({
        path: path,//设置多边形边界路径
        strokeColor: "#00FF00", //线颜色
        strokeOpacity: 1, //线透明度
        strokeWeight: 3,    //线宽
        fillColor: "#FF0000", //填充色
        fillOpacity: 0.2//填充透明度
    })
    polygon.setMap(map)

    if (extdata !== undefined) {
        polygon.setExtData(extdata)
    }
}

function drawRestrictionsMap(map, restrictionsArray) {
    let centerPoint = getRestrictionsMapCenterPoint(restrictionsArray)
    map.setCenter(centerPoint)

    for (const restrictions of restrictionsArray) {
        for (const restriction of restrictions) {
            drawMapWithGeoInfos(map, restriction["geom"], restriction)
        }
    }
}

function getRestrictionsMapCenterPoint(restrictionsArray) {

    let x = 0
    let y = 0
    let count = 0

    for (const restrictions of restrictionsArray) {
        for (const restriction of restrictions) {
            for (const geoInfo of restriction["geom"]) {
                for (const geo of geoInfo.geo) {
                    x += parseFloat(geo.x)
                    y += parseFloat(geo.y)
                    count += 1
                }
            }
        }
    }

    return [x / count, y / count]
}

function showRestrictionRuleModal(modalBodyHTML) {
    let $mapModal = $('#restrictionRuleModal')

    $mapModal.find('.modal-body').html(modalBodyHTML)
    $mapModal.modal("show")
}

window.showMap = showMap
window.drawRestrictionsMap = drawRestrictionsMap
window.showRestrictionRuleModal = showRestrictionRuleModal
