import {Controller} from "stimulus"
import $ from "jquery"
import {drawRestrictionsMap} from "../helpers/map_helpers"
import {useAMap} from "../mixins/amap"

export default class extends Controller {
    static targets = [
        "map",
        "address",
        "rulesModal",
        "rulesContent",
        "restrictions",
        "truckTypeSelect",
    ]

    connect() {
        this.loadRestrictions()
        this.initTruckTypeSelect()
        this.initMap()
    }

    loadRestrictions() {
        this.restrictions = this.parseRestrictionTarget(this.restrictionsTarget)
    }

    parseRestrictionTarget(target) {
        let value = target.value

        if (value && value !== "[]" && value !== "{}") {
            return JSON.parse(value)
        } else {
            return []
        }
    }

    initMap() {
        useAMap(this)

        this.loadMap(this.mapTarget, 16, (map) => {
            this.map = map
            drawRestrictionsMap(this.map, [this.restrictions])
            this.setAllOverlays()
        })
    }

    initTruckTypeSelect() {
        let selectElement = this.truckTypeSelectTarget.querySelector("select")
        if (selectElement) {
            selectElement.onchange = () => {
                let selectedIndex = selectElement.selectedIndex
                if (selectedIndex !== -1){
                    let value = selectElement.options[selectedIndex].value
                    document.location = `/truck_restrictions/map?truck_type=${value}`
                }
            }
        }

    }

    setAllOverlays() {
        for (let overlay of this.map.getAllOverlays()) {
            overlay.on('click', this.onOverlayClick)
            overlay.setOptions({"cursor": "pointer"})
        }
    }

    onOverlayClick = (e) => {
        /*
          e.target is polyline or polygon object created by calling of drawRestrictionsMap,
          e.target.extdata is the corresponding restriction rule object, set by
          calling of setExtData of drawRestrictionsMap (xianxing_show.js)
         */
        let selectedRules = []
        let idSeen = {}

        for (let overlay of this.map.getAllOverlays()) {
            try {
                if (overlay.contains(e.lnglat)) {
                    let rule = overlay.getExtData()
                    if (!idSeen[rule.id]) {
                        selectedRules.push(rule)
                        idSeen[rule.id] = true
                    }
                }
            } catch (e) {
            }
        }

        let bodyHtml = this.generateHTMLFromRules(selectedRules)
        this.showRulesModal(bodyHtml)
    }

    generateHTMLFromRules(rules) {
        let result = ""
        let index = 1

        for (let rule of rules) {
            let time = rule.time
            if (rule.time === "") {
                time = "全天"
            }

            let date = rule.date

            result += `
                <h5 class="title is-5 has-text-orange">限行规则${index}</h5>
                <div class="block">
                  <span class="has-text-weight-bold has-text-primary">ID:</span>
                  <p>${rule.id}</p>
                </div>
                <div class="block">
                  <span class="has-text-weight-bold has-text-primary">限行日期:</span>
                  <p>${date}</p>
                </div>
                <div class="block">
                  <span class="has-text-weight-bold has-text-primary">限行时间:</span>
                  <p>${time}</p>
                </div>
                <div class="block">
                  <span class="has-text-weight-bold has-text-primary">限行规定:</span>
                  <p>${rule.rules}</p>
                </div>
                <div class="block">
                  <span class="has-text-weight-bold has-text-primary">限行区域:</span>
                  <p>${rule.area}</p>
                </div>
                <hr>
            `

            index += 1
        }

        return result
    }

    showRulesModal(content) {
        this.rulesContentTarget.innerHTML = content
        this.rulesModalTarget.classList.add("is-active")
    }
}
