import {Controller} from "stimulus"
import "@amap/amap-jsapi-types"
import $ from "jquery"
import autocomplete from "controllers/autocomplete"
import { GeomAutoCompleteItem } from "controllers/geom_autocomplete_item"

enum RuleType {
    Local = 1,
    Foreign,
    All
}

export default class extends Controller {
    static targets = [
        "map",
        "address",
        "lnglat",
        "localRestrictions",
        "foreignRestrictions",
        "allRestrictions"]

    mapTarget: HTMLDivElement
    localRestrictionsTarget: HTMLInputElement
    foreignRestrictionsTarget: HTMLInputElement
    allRestrictionsTarget: HTMLInputElement
    addressTarget: HTMLInputElement
    lnglatTarget: HTMLInputElement

    private map: AMap.Map
    private mapAutoComplete: AMap.AutoComplete
    private localRestrictions: any
    private foreignRestrictions: any
    private allRestrictions: any
    private weekInfo: string[]

    connect() {
        this.loadRestrictions()
        this.initMap()
        this.initAddressAutocomplete()
    }

    private loadRestrictions() {
        this.localRestrictions = this.parseRestrictionTarget(this.localRestrictionsTarget)
        this.foreignRestrictions = this.parseRestrictionTarget(this.foreignRestrictionsTarget)
        this.allRestrictions = this.parseRestrictionTarget(this.allRestrictionsTarget)

        this.weekInfo = this.parseWeekInfo()
    }

    private parseRestrictionTarget(target: HTMLInputElement): any {
        let value = target.value

        if (value && value != "[]" && value != "{}") {
            return JSON.parse(value)
        } else {
            return []
        }
    }

    private parseWeekInfo(): string[] {
        let value = this.data.get("weekInfo")

        if (value) {
            return JSON.parse(value).map((d) => "周" + d)
        } else {
            return []
        }
    }

    private initMap() {
        const mapOptions: AMap.MapOptions = {
            zoom: 16,
        }

        this.map = new AMap.Map(this.mapTarget, mapOptions)
        this.map.on("click", this.onMapClick)

        drawRestrictionsMap(this.map,
            [this.localRestrictions, this.foreignRestrictions, this.allRestrictions])

        this.setAllOverlays()

        this.map.setFitView()
    }

    private setAllOverlays() {
        for (let overlay of this.map.getAllOverlays()) {
            overlay.on('click', this.onOverlayClick)
            overlay.setOptions({"cursor": "pointer"})
        }
    }

    private initAddressAutocomplete() {
        this.mapAutoComplete = new AMap.AutoComplete()

        autocomplete<GeomAutoCompleteItem>({
            minLength: 1,
            preventSubmit: true,
            input: this.addressTarget,
            attachedClassName: "restriction-map-show",
            className: 'restriction-map-show__autocomplete',
            emptyMsg: '该地址没有找到',
            debounceWaitMs: 200,
            fetch: (text, update) => {
                this.mapAutoComplete.search(text, (status, result) => {
                    if (status == 'complete') {
                        let tips = result.tips.filter((tip) => {
                            return tip.location != ''
                        })

                        let items = tips.map((tip) => {
                            return {label: tip.name, district: tip.district, location: tip.location}
                        })

                        update(items)
                    }
                })
            },
            render: (item, currentValue) => {
                let div = document.createElement('div')
                div.innerHTML = `${item.label}<span>${item.district}</span>`
                return div
            },
            onSelect: (item) => {
                this.addressTarget.value = item.label
                this.addMarker([item.location.lng, item.location.lat])
            }
        })

        let city = this.data.get("city")
        if (city.length > 1) {
            this.mapAutoComplete.setCity(city)
        }

        $(this.addressTarget).focus()
    }

    private addMarker(lnglat: [number, number]) {
        let marker = new AMap.Marker()

        marker.setPosition(lnglat)
        this.map.add(marker)
        this.map.setFitView([marker], false, [60, 60, 60, 60], 18)
    }

    private onMapClick = (e: any) => {
        this.lnglatTarget.value = e.lnglat.toString()
    }

    private onOverlayClick = (e: any) => {
        /*
          e.target is polyline or polygon object created by calling of drawRestrictionsMap,
          e.target.extdata is the corresponding restriction rule object, set by
          calling of setExtData of drawRestrictionsMap (xianxing_show.js)
         */
        let selectedRules = []
        let idSeen = {}

        for (let overlay of this.map.getAllOverlays()) {
            try {
                if (overlay.contains(e.lnglat)) {
                    let rule = overlay.getExtData()
                    if (!idSeen[rule.id]) {
                        selectedRules.push(rule)
                        idSeen[rule.id] = true
                    }
                }
            } catch (e) {
            }
        }

        selectedRules.sort((a, b) => a["type"] - b["type"])

        let bodyHtml = this.generateHTMLFromRules(selectedRules)
        showRestrictionRuleModal(bodyHtml)

        this.lnglatTarget.value = e.lnglat.toString()
    }

    private generateHTMLFromRules(rules: any[]): string {
        let result: string = ""
        let index = 1

        let weekInfoHtml = this.generateWeekInfoHtml()

        for (let rule of rules) {
            let ruleType = ""

            switch (rule["type"]) {
                case RuleType.Local:
                    ruleType = "本地";
                    break;
                case RuleType.Foreign:
                    ruleType = "外地";
                    break;
                case RuleType.All:
                    ruleType = "本地和外地";
                    break;
            }

            let daysDescHtml = this.generateDaysDescHtml(rule["daysDesc"])

            let time = rule.time
            if (rule.time == "") {
                time = "全天"
            }

            result += `
                <h4 class="detailNo">限行规则${index}</h4>
                <dl>
                    <table class="table-bordered">
                       ${weekInfoHtml}
                       ${daysDescHtml}
                    </table>
                    <dt>车牌归属地:</dt>
                    <dd>${ruleType}</dd>
                    <dt>限行时间:</dt>
                    <dd>${time}</dd>
                    <dt>限行规定:</dt>
                    <dd>${rule.rules}</dd>
                    <dt>限行区域:</dt>
                    <dd>${rule.area}</dd>
                    <hr>
                </dl>
            `

            index += 1
        }

        return result
    }

    private generateWeekInfoHtml(): string {
        let result = "<tr>"
        let i = 1

        for (let d of this.weekInfo) {
            if (i == 1) {
                result += `<td>今天(${d})</td>`
            } else if (i == 2) {
                result += `<td>明天(${d})</td>`
            } else {
                result += `<td>${d}</td>`
            }

            i += 1
        }

        result += "</tr>"
        return result
    }

    private generateDaysDescHtml(daysDesc: string): string {
        let result = "<tr>"

        for (let d of daysDesc.split(";")) {
            result += `<td>${d.replace(/[,]/g, '和')}</td>`
        }

        result += "</tr>"
        return result
    }

    private onLnglatKeydown(event: KeyboardEvent) {
        if (event.key == "Enter") {
            if (this.lnglatTarget.value.length == 0)
                return

            let [longitudeStr, latitudeStr] = this.lnglatTarget.value.split(/[,，]/)

            let longitude = parseFloat(longitudeStr)
            let latitude = parseFloat(latitudeStr)

            if  (isNaN(longitude) || isNaN(latitude)) {
                alert("经纬度无效")
                return
            }

            this.addMarker([longitude, latitude])
            event.stopPropagation()
        }
    }
}
