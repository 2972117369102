interface EditorCallback {
    onAdd(target: any)
    onEnd(target: any)
    onAddNode()
    onRemoveNode()
    onAdjustNode()
    onDelete(target: any)
    onDblclick(target: any)
    onToggleRestricted(target: any)
}

export class ShapeEditor<T extends AMap.PolygonEditor | AMap.PolylineEditor> {
    private editor: T
    private contextMenu: AMap.ContextMenu

    constructor(private editorType: new (map: AMap.Map) => T,
                private map: AMap.Map,
                private editorCallback: EditorCallback) {

        this.editor = new this.editorType(this.map)
        this.editor.on('add', this.onEditorAdd)
        this.editor.on('end', this.onEditorEnd)
        this.editor.on('addnode', this.onEditorAddNode)
        this.editor.on('removenode', this.onEditorRemoveNode)
        this.editor.on('adjust', this.onEditorAdjustNode)

        this.contextMenu = new AMap.ContextMenu()

        this.contextMenu.addItem('切换限行', this.onToggleRestrictType, 0)
        this.contextMenu.addItem('隐 藏', this.onTargetHide, 0)
        this.contextMenu.addItem('删 除', this.onTargetDelete, 0)
    }

    public open() {
        // because AMap will reset extData when call polylineEditor close,
        // so do patch.
        let target = this.editor.getTarget()
        let oldExtData = target?.getExtData()

        this.editor.close()

        if (target && oldExtData) {
            target.setExtData(oldExtData)
        }

        this.editor.setTarget()
        this.editor.open()
    }

    public close() {
        this.editor.close()
    }

    public mount(target: any) {
        target.on('dblclick', this.onTargetDblClick)
        target.on('rightclick', this.onTargetRightClick)
    }

    public closeContextMenu() {
        this.contextMenu.close()
    }

    private onEditorAdd = (e: any) => {
        let target = e.target
        if (target) {
            this.editorCallback.onAdd(target)
            this.mount(target)
        }
    }

    private onEditorEnd = (e: any) => {
        let target = e.target
        if (target) {
            this.editorCallback.onEnd(target)
        }
    }

    private onEditorAddNode = (e: any) => {
        this.editorCallback.onAddNode()
    }

    private onEditorRemoveNode = (e: any) => {
        this.editorCallback.onRemoveNode()
    }

    private onEditorAdjustNode = (e: any) => {
        this.editorCallback.onAdjustNode()
    }

    private onTargetDblClick = (e: any) => {
        let target = e.target

        this.editorCallback.onDblclick(target)

        this.editor.setTarget(target)
        this.editor.open()
    }

    private onTargetRightClick = (e: any) => {
        this.editor.setTarget(e.target)
        this.contextMenu.open(this.map, e.lnglat)
    }

    private onTargetHide = (e: any) => {
        this.editor.getTarget()?.hide()
    }

    private onTargetDelete = (e: any) => {
        let target = this.editor.getTarget()
        if (target) {
            if (confirm("是否真的要删除?")) {
                this.editor.close()
                this.editorCallback.onDelete(target)
                target.setMap(null)
            }
        }
    }

    private onToggleRestrictType = (e: any) => {
        let target = this.editor.getTarget()
        this.editorCallback.onToggleRestricted(target)
    }
}