// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap"
import "xianxing"
import "controllers"

window.$ = $
require("trix")
require("@rails/actiontext")

// For bulma
document.addEventListener('turbolinks:load', () => {
    // notification
    (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
        const $notification = $delete.parentNode

        $delete.addEventListener('click', () => {
            $notification.parentNode.removeChild($notification)
        })
    })

    // dropdown
    const $dropdowns = document.querySelectorAll('.dropdown:not(.is-hoverable)');

    if ($dropdowns.length > 0) {
        $dropdowns.forEach(function ($el) {
            $el.addEventListener('click', function (event) {
                event.stopPropagation();
                $el.classList.toggle('is-active');
            });
        });

        document.addEventListener('click', function (event) {
            closeDropdowns();
        });
    }

    function closeDropdowns() {
        $dropdowns.forEach(function ($el) {
            $el.classList.remove('is-active');
        });
    }

    // modal background
    (document.querySelectorAll('.modal-background') || []).forEach(($modalBackground) => {
        const $modal = $modalBackground.parentNode

        $modalBackground.addEventListener('click', (event) => {
            $modal.classList.remove("is-active")
        })
    })
})