import {Controller} from "stimulus"
import "@amap/amap-jsapi-types"
import $ from "jquery"
import { ShapeEditor} from "controllers/shape_editor"
import autocomplete from "controllers/autocomplete"
import { GeomAutoCompleteItem } from "controllers/geom_autocomplete_item"

enum ShapeKind {
    NonRestrictedPolygon = 1,
    RestrictedPolygon,
    NonRestrictedLine,
    RestrictedLine,
    UnknownShape,
}

enum JSONShapeType {
    Line = 0,
    Polygon,
    LineAndPolygon
}

type GeomShape = AMap.Polygon | AMap.Polyline

export default class extends Controller {
    static targets = [
        "mapWidget",
        "address",
        "locationCard",
        "geom",
        "resize"]

    mapWidgetTarget: HTMLDivElement
    addressTarget: HTMLInputElement
    resizeTarget: HTMLButtonElement
    locationCardTarget: HTMLDivElement
    geomTarget: HTMLInputElement

    private map: AMap.Map
    private mapContextMenu: AMap.ContextMenu
    private mapAutoComplete: AMap.AutoComplete
    private polygonEditor: ShapeEditor<AMap.PolygonEditor>
    private polylineEditor: ShapeEditor<AMap.PolylineEditor>

    private selectedShape: ShapeKind
    private shapeKindMap: Map<GeomShape, ShapeKind>
    private shapeStack: any[]

    private geomValueChanged: boolean

    connect() {
        this.selectedShape = ShapeKind.UnknownShape
        this.shapeKindMap  = new Map<GeomShape, ShapeKind>()
        this.shapeStack    = []

        this.initMap()
        this.initEditor()
        this.initAddressAutocomplete()
    }

    addressKeydown(event: KeyboardEvent) {
        if (event.code == 'Enter') {
            event.preventDefault()
            return false
        }

        return true
    }

    createNonRestrictedPolygon() {
        this.closeEditors()

        this.selectedShape = ShapeKind.NonRestrictedPolygon
        this.polygonEditor.open()
    }

    createRestrictedPolygon() {
        this.closeEditors()

        this.selectedShape = ShapeKind.RestrictedPolygon
        this.polygonEditor.open()
    }

    createNonRestrictedLine() {
        this.closeEditors()

        this.selectedShape = ShapeKind.NonRestrictedLine
        this.polylineEditor.open()
    }

    createRestrictedLine() {
        this.closeEditors()

        this.selectedShape = ShapeKind.RestrictedLine
        this.polylineEditor.open()
    }

    toggleLocationCard() {
        $(this.locationCardTarget).toggle('fast', () => {
            if ($(this.locationCardTarget).is(':visible')) {
                let city = this.defaultCity()
                if (city.length > 1) {
                    this.mapAutoComplete.setCity(city)
                }

                $(this.addressTarget).focus()
            }
        })
    }

    onResize() {
        this.element.classList.toggle("geom-editor--full")

        let iconTag = this.resizeTarget.querySelector("i")
        if (iconTag.className == "icon-resize-full") {
            iconTag.className = "icon-resize-small"
        } else {
            iconTag.className = "icon-resize-full"
        }
    }

    private initMap() {
        const mapOptions: AMap.MapOptions = {
            zoom: 16,
            doubleClickZoom: false,
        }

        this.map = new AMap.Map(this.mapWidgetTarget, mapOptions)
        this.map.on('rightclick', this.onMapRightClick)
        this.map.on('click', this.onMapClick)

        this.loadGeomJSON()
        this.geomValueChanged = false

        this.mapContextMenu = new AMap.ContextMenu()
        this.mapContextMenu.addItem('显示所有', this.onShowAll, 0)
        this.mapContextMenu.addItem('撤    销', this.onCancel, 1)

        // If exportGeomPath not be blank, usually means that
        // the record be in edit state, then add export menu item
        if (this.data.get("exportGeomPath").length > 0) {
           this.mapContextMenu.addItem('导  出', this.onExport, 2)
        }

        this.mapContextMenu.addItem('从本地文件导入轨迹点', this.importLocalData, 3)
    }

    private initEditor() {
        let editorCallback = {
            onAdd: this.onEditorAdd,
            onDelete: this.onEditorDelete,
            onEnd: this.onEditorEnd,
            onAddNode: this.onEditorUpdateNode,
            onRemoveNode: this.onEditorRemoveNode,
            onAdjustNode: this.onEditorUpdateNode,
            onDblclick: this.onEditorDblclick,
            onToggleRestricted: this.onEditorToggleRestricted,
        }

        this.polygonEditor = new ShapeEditor<AMap.PolygonEditor>(AMap.PolygonEditor, this.map, editorCallback)
        this.polylineEditor = new ShapeEditor<AMap.PolylineEditor>(AMap.PolylineEditor, this.map, editorCallback)

        for (let shape of this.shapeStack) {
            let kind = this.shapeKindMap.get(shape)

            switch (kind) {
                case ShapeKind.RestrictedLine:
                case ShapeKind.NonRestrictedLine:
                    this.polylineEditor.mount(shape)
                    break

                case ShapeKind.RestrictedPolygon:
                case ShapeKind.NonRestrictedPolygon:
                    this.polygonEditor.mount(shape)
                    break
            }
        }
    }

    private initAddressAutocomplete() {
        this.mapAutoComplete = new AMap.AutoComplete()

        autocomplete<GeomAutoCompleteItem>({
            minLength: 1,
            preventSubmit: true,
            input: this.addressTarget,
            className: 'geom-editor__autocomplete',
            emptyMsg: '该地址没有找到',
            debounceWaitMs: 200,
            fetch: (text, update) => {
                this.mapAutoComplete.search(text, (status, result) => {
                    if (status == 'complete') {
                        let tips = result.tips.filter((tip) => {
                            return tip.location != ''
                        })

                        let items = tips.map((tip) => {
                            return {label: tip.name, district: tip.district, location: tip.location}
                        })

                        update(items)
                    }
                })
            },
            render: (item, currentValue) => {
                let div = document.createElement('div')
                div.innerHTML = `${item.label}<span>${item.district}</span>`
                return div
            },
            onSelect: (item) => {
                this.addressTarget.value = item.label
                this.addMarker([item.location.lng, item.location.lat])
            }
        })
    }

    private addMarker(lnglat: [number, number]) {
        let marker = new AMap.Marker()

        marker.setPosition(lnglat)
        this.map.add(marker)
        this.map.setCenter(lnglat)

        setTimeout(() => {
            marker.setMap(null)
        }, 20000)
    }

    private defaultCity() {
        let id = this.data.get('citySelectId')
        return $(`#${id}`).find('option:selected').text()
    }

    private closeEditors() {
        this.polylineEditor.close()
        this.polygonEditor.close()
    }

    private attachShape(shape: any, kind: ShapeKind, saved: boolean = true) {
        this.shapeKindMap.set(shape, kind)
        this.shapeStack.push(shape)

        if (saved) {
            this.saveGeomJSON()
        }
    }

    private detachShape(shape: any) {
        this.shapeKindMap.delete(shape)

        let index = this.shapeStack.indexOf(shape)
        if (index != -1) {
            this.shapeStack.splice(index, 1)
        }

        this.saveGeomJSON()
    }

    private toggleShapeRestricted(shape: any) {
        let kind = this.shapeKindMap.get(shape)
        if (kind) {
            let newKind: ShapeKind | undefined

            switch (kind) {
                case ShapeKind.RestrictedLine:
                    newKind = ShapeKind.NonRestrictedLine
                    break
                case ShapeKind.NonRestrictedLine:
                    newKind = ShapeKind.RestrictedLine
                    break
                case ShapeKind.RestrictedPolygon:
                    newKind = ShapeKind.NonRestrictedPolygon
                    break
                case ShapeKind.NonRestrictedPolygon:
                    newKind = ShapeKind.RestrictedPolygon
                    break
            }

            if (newKind) {
                this.shapeKindMap.set(shape, newKind)
                this.saveGeomJSON()
            }
        }
    }

    private onEditorAdd = (target: any) => {
        this.attachShape(target, this.selectedShape)
    }

    private onEditorDelete = (target: any) => {
        this.detachShape(target)
    }

    private onEditorEnd = (target: any) => {
        this.resetShapeStyle(target);
    }

    private onEditorUpdateNode = () => {
        this.saveGeomJSON()
    }

    private onEditorRemoveNode = () => {
        // when remove last node for the polyline, polyline will
        // be removed from the map, so make a patch.
        setTimeout(() => {
            let overlays = this.map.getAllOverlays()
            let removedShape = undefined

            for (let shape of this.shapeStack) {
                if (overlays.indexOf(shape) == -1) {
                   removedShape = shape
                   break
                }
            }

            if (removedShape) {
                this.detachShape(removedShape)
            } else {
                this.saveGeomJSON()
            }
        }, 5)
    }

    private resetShapeStyle(shape: any) {
        let options = shape.getOptions()

        switch (this.shapeKindMap.get(shape)) {
            case ShapeKind.NonRestrictedPolygon:
                options.strokeColor = '#00FF00'
                options.strokeWidth = 3
                options.strokeOpacity = 1
                options.fillColor = '#FF0000'
                options.fillOpacity = 0.2
                break

            case ShapeKind.RestrictedPolygon:
                options.strokeColor = '#FF0000'
                options.strokeWidth = 3
                options.strokeOpacity = 1
                options.fillColor = '#FF0000'
                options.fillOpacity = 0.2
                break

            case ShapeKind.NonRestrictedLine:
                options.strokeColor = '#00FF00'
                options.strokeWidth = 3
                options.strokeOpacity = 1
                break

            case ShapeKind.RestrictedLine:
                options.strokeColor = '#ff0000'
                options.strokeWidth = 3
                options.strokeOpacity = 1
                break
        }

        shape.setOptions(options)
    }

    private onEditorDblclick = (target: any) => {
        this.closeEditors()
    }

    private onEditorToggleRestricted = (target: any) => {
        this.toggleShapeRestricted(target)
        this.resetShapeStyle(target)
    }

    private onMapRightClick = (ev: any) => {
        const lnglat = ev.lnglat
        let clickedShape: any = undefined

        for (let overlay of this.map.getAllOverlays()) {
            if (overlay.contains(lnglat)) {
                clickedShape = overlay
            }
        }

        // If right click event happens inside shape, and the shape is visible,
        // then not handle, let the shape self handle the right click event, otherwise,
        // show map context menu.
        if (clickedShape === undefined || !clickedShape.visible) {
            // delay close editor to prevent from removing a new shape by the editor.
            setTimeout(() => {this.closeEditors()}, 0)
            this.mapContextMenu.open(this.map, lnglat)
        }
    }

    private onMapClick = (ev: any) => {
        this.mapContextMenu.close()
        this.polygonEditor.closeContextMenu()
        this.polylineEditor.closeContextMenu()
    }

    private onShowAll = (e: any) => {
        for (let shape of this.map.getAllOverlays()) {
            shape.show()
        }
    }

    private onCancel = (e: any) => {
        if (this.shapeStack.length > 0 && confirm("是否真的要撤销最近一次添加的图形?")) {
            let shape = this.shapeStack.pop()

            this.closeEditors()

            shape.setMap(null)
            this.detachShape(shape)
        }
    }

    private onExport = (e: any) => {
        if (this.geomValueChanged) {
            alert("矢量图数据已经改变，请保存后重新编辑该记录，在进行导出!")
            return
        }
        window.location.href = this.data.get("exportGeomPath")
    }

    private importLocalData = (event) => {

        const fileInput = document.createElement("input")

        fileInput.setAttribute("type", "file")
        fileInput.setAttribute("style", "visibility:hidden")
        fileInput.setAttribute("accept", ".txt")

        fileInput.addEventListener("change", () => {
            const file = fileInput.files[0]
            if (file.size > 1048576 * 5) {
                document.body.removeChild(fileInput)
                alert(`${file.name} 超过5M, 不能导入!`)

                return
            }

            this.map.setDefaultCursor("wait")

            const reader = new FileReader()

            reader.onload = (event) => {
                const content = event.target.result
                document.body.removeChild(fileInput)

                this.loadDataFromLnglats(content)

                this.map.setDefaultCursor("default")
            }

            reader.readAsText(file, "UTF-8")
        })

        document.body.appendChild(fileInput)
        fileInput.click()
    }

    private loadDataFromLnglats(content) {
        const lnglatStrArray = content.split(/\r\n|\n/)
        const path = []

        for (const lnglatStr of lnglatStrArray) {
            const [longitudeStr, latitudeStr] = lnglatStr.split(",")

            const longitude = parseFloat(longitudeStr)
            const latitude = parseFloat(latitudeStr)

            if (!isNaN(longitude) && !isNaN(latitude)) {
                path.push([longitude, latitude])
            }
        }

        if (path.length === 0) {
            this.map.setDefaultCursor("default")
            alert("没有找到经纬度数据，请确认数据格式是否正确!")

            return
        }

        const firstPoint = path[0]
        const lastPoint  = path[path.length - 1]

        let shapeKind = null
        let shapeEditor = null

        if (firstPoint[0] === lastPoint[0] && firstPoint[1] === lastPoint[1]) {
            shapeKind = ShapeKind.RestrictedPolygon
            shapeEditor = this.polygonEditor
        } else {
            shapeKind = ShapeKind.RestrictedLine
            shapeEditor = this.polylineEditor
        }

        const shape = this.makeShape(shapeKind, path)
        this.mountShape(shape, shapeKind, true)
        shapeEditor.mount(shape)

        this.map.setFitView()
    }

    private loadGeomJSON() {
        let geomValue = this.geomTarget.value

        if (geomValue && geomValue != "[]" && geomValue != "{}") {
            let geomList = JSON.parse(geomValue)

            if (!Array.isArray(geomList)) {
                geomList = [geomList]
            }

            let count   = 0
            let centerX = 0
            let centerY = 0

            for (let geom of geomList ) {
                let kind = this.loadShapeKind(geom)

                if (kind != ShapeKind.UnknownShape) {
                    let path = this.loadGeoData(geom)
                    let shape = this.makeShape(kind, path)

                    this.mountShape(shape, kind)

                    // calc center point
                    for (let geo of path) {
                        count += 1
                        centerX += geo[0]
                        centerY += geo[1]
                    }
                }
            }

            this.map.setCenter([centerX / count, centerY / count])
            this.map.setFitView()
        }
    }

    private loadGeoData(geom: any) {
        return geom['geo'].map((lnglat) => {
            return [lnglat.x, lnglat.y]
        })
    }

    private loadShapeKind(geom: any) {
        let infos = geom['info']
        if (infos.length == 1) {
            let info = infos[0]

            switch (true) {
                case info.isrestrict && info.shapetype == JSONShapeType.LineAndPolygon:
                    return ShapeKind.RestrictedPolygon
                case info.isrestrict && info.shapetype == JSONShapeType.Line:
                    return ShapeKind.RestrictedLine
                case !info.isrestrict && info.shapetype == JSONShapeType.LineAndPolygon:
                    return ShapeKind.NonRestrictedPolygon
                case !info.isrestrict && info.shapetype == JSONShapeType.Line:
                    return ShapeKind.NonRestrictedLine
            }
        } else if (infos.length == 2) {
            let [info0, info1] = infos

            if ((info0.isrestrict && info0.shapetype == JSONShapeType.Polygon) &&
                (!info1.isrestrict && info1.shapetype == JSONShapeType.Line)) {
                return ShapeKind.NonRestrictedPolygon
            }
        }

        return ShapeKind.UnknownShape
    }

    private makeShape(kind: ShapeKind, path: any): AMap.Polyline | AMap.Polygon {
        switch (kind) {
            case ShapeKind.NonRestrictedPolygon:
            case ShapeKind.RestrictedPolygon:
                return new AMap.Polygon({path: path, strokeWeight: 6})
            case ShapeKind.RestrictedLine:
            case ShapeKind.NonRestrictedLine:
                return new AMap.Polyline(({path: path, strokeWeight: 6}))
        }
    }

    private mountShape(shape: AMap.Polyline | AMap.Polygon, kind: ShapeKind, saved: boolean = false) {
        this.map.add(shape)

        this.attachShape(shape, kind, saved)
        this.resetShapeStyle(shape)
    }

    private saveGeomJSON() {
        this.geomTarget.value = this.makeGeomJSON()
        this.geomValueChanged = true
    }

    private makeGeomJSON() {
        let result: any[] = []

        for (let shape of this.shapeStack) {
            let kind = this.shapeKindMap.get(shape)

            result.push({"geo": this.makeGeoData(shape, kind),
                         "info": this.makeInfoData(kind)})
        }

        return JSON.stringify(result)
    }

    private makeGeoData(shape: any, kind: ShapeKind | undefined) {
        let path = shape.getPath()

        let geo = path.map((lnglat: any) => {
            return {"x": lnglat.lng, "y": lnglat.lat}
        })

        if (path.length > 0 &&
            (kind == ShapeKind.NonRestrictedPolygon || kind == ShapeKind.RestrictedPolygon)) {
            geo.push({"x": path[0].lng, "y": path[0].lat})
        }

        return geo
    }

    private makeInfoData(kind: ShapeKind | undefined) {
        let info: any[] = []

        switch (kind) {
            case ShapeKind.RestrictedPolygon:
                info = [{"isrestrict": true, "shapetype": JSONShapeType.LineAndPolygon}]
                break
            case ShapeKind.NonRestrictedPolygon:
                info = [{"isrestrict": false, "shapetype": JSONShapeType.LineAndPolygon}]
                break
            case ShapeKind.RestrictedLine:
                info = [{"isrestrict": true, "shapetype": JSONShapeType.Line}]
                break
            case ShapeKind.NonRestrictedLine:
                info = [{"isrestrict": false, "shapetype": JSONShapeType.Line}]
                break
        }

        return info
    }
}
