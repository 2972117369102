function initCitySelect() {
	var xianxCityDivCapture = false;

	$("#xianxCitySelect").on("click", function() {
		$("#xianxCityDiv").toggle(function() {
			$("#xianxCityList").show();
			xianxCityDivCapture = true;
		});
	});

	$("#xianxCityList a").on("click", function(event) {
		let cityId = event.target.getAttribute("data-id");
		let mapValue = event.target.getAttribute("data-map");

		if (mapValue === "1" || event.ctrlKey) {
			searchCitys(cityId, true)
		} else {
			searchCitys(cityId, false);
		}

		event.preventDefault();
	})

	$("#closeBtn").on("click", function() {
		$("#xianxCityDiv").hide();
	});

	$(document).on('click', function(event) {
		if (xianxCityDivCapture) {
			if (event.target.id !== 'xianxCitySelect') {
				$xianxCityDiv = $('#xianxCityDiv');

				if ($xianxCityDiv.is(':visible')) {
					let x = event.pageX;
					let y = event.pageY;

					let y1 = $xianxCityDiv.offset().top;
					let y2 = y1 + $xianxCityDiv.height();

					let x1 = $xianxCityDiv.offset().left;
					let x2 = x1 + $xianxCityDiv.width();

					if (x < x1 || x > x2 || y < y1 || y > y2) {
						$xianxCityDiv.hide();
						xianxCityDivCapture = false;
					}
				} else {
					xianxCityDivCapture = false;
				}
			}
		}
	});
}

function searchCitys(cityId, isShowMap) {
	$("#xianxCityDiv").hide();
	$("#overlay").show();
	setTimeout(function() {
	    if (isShowMap) {
			window.location.href = "/traffic_restrictions/" + cityId + "/map";
		} else {
			window.location.href = "/traffic_restrictions/" + cityId;
		}
	}, 100);
}

window.initCitySelect = initCitySelect;