import AMapLoader from "@amap/amap-jsapi-loader"

export const useAMap = controller => {
    Object.assign(controller, {
        loadMap(mapTarget, zoomLevel, onCompleteCallback) {
            AMapLoader.load({
                "key": "853892611c897a9ab6731d8386d96b1c",
                "version": "2.0"
            }).then((AMap) => {
                let map = new AMap.Map(mapTarget, {zoom: zoomLevel})

                map.on("complete", (event) => {
                    onCompleteCallback(map)
                    map.setFitView(null, false, [0, 0, 0, 0])
                })
            }).catch(e => {
                console.log(e)
            })
        },

        loadMapWithPlugins(mapTarget, zoomLevel, plugins, onCompleteCallback) {
            AMapLoader.load({
                "key": "853892611c897a9ab6731d8386d96b1c",
                "version": "2.0",
                plugins: plugins
            }).then((AMap) => {
                let map = new AMap.Map(mapTarget, {zoom: zoomLevel})

                map.on("complete", (event) => {
                    onCompleteCallback(AMap, map)
                    map.setFitView(null, false, [0, 0, 0, 0])
                })
            }).catch(e => {
                console.log(e)
            })
        },

        makePathOnMap(map, locations, color = "#EF4444") {
            if (locations.length === 0) {
                return
            }

            const path = locations.map((point) => [point.x, point.y])

            const polyline = new AMap.Polyline({
                path: path,
                strokeColor: color,
                strokeOpacity: 1,
                strokeWeight: 5,
                strokeStyle: "solid",
                strokeDasharray: [10, 5]
            })

            map.add(polyline)

            return polyline
        },

        makeCirclesOnMap(map, locations, onClickCallback) {
            if (locations.length === 0) {
                return
            }

            const circles = locations.map((point) => {
                let circle = new AMap.Circle({
                    center: [point.x, point.y],
                    radius: 2,
                    cursor: 'pointer',
                    fillColor: 'white',
                    fillOpacity: 1,
                    strokeColor: '#EF4444',
                    strokeOpacity: 0.5,
                    strokeWidth: 0,
                    zIndex: 50,
                })

                // Set ext data be the time of the location, the time info will be used
                // by onCircleClick
                circle.setExtData(point.t)
                circle.on('click', onClickCallback)

                return circle
            })

            map.add(circles)
        },

        makeMarkerOnMap(map, point) {
            let marker = new AMap.Marker({
                position: new AMap.LngLat(point.x, point.y),
            })

            map.add(marker)

            return marker
        }
    })
}